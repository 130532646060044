/*****************************************************************************
 *** gpServerEmulator() - Fake GP Server to handle Get Session API Request ***
 *****************************************************************************
 Note: In Live environment this functionallity will exist on Guestpoint Server as an API endpoint
 */
import axios from 'axios'
import _get from 'lodash/get'

const gpServerEmulator = async (obj: any) => {
  //Get Merchant based on User Code (This will be known to GP Server)
  let merchant = ''
  let user = _get(obj, 'user', null)
  switch (user) {
    case 'ddtis':
      merchant = 'GUESTPOINT'
      break
    case 'vikastaxmode2':
      merchant = 'EWAYTEST1'
      break
    case 'vikasvillage':
      merchant = 'PAYRIX'
      break
    default:
      merchant = ''
  }

  //===============================================================================
  //GET SESSION FROM PAYMENT GATEWAY SERVER - GP SERVER WILL MAKE THIS HTTP REQUEST
  //===============================================================================
  try {
    let result: any
    let obj = {
      method: 'POST',
      url: `${process.env.REACT_APP_PAYGATE_SERVER}/api/session/client`,
      headers: {
        'X-API-KEY': `${process.env.REACT_APP_PAYGATE_APIKEY}`,
      },
      data: {
        merchant: merchant,
      },
    }
    console.log('GET SESSION OBJECT ', obj)

    try {
      result = await axios(obj)
      console.log('GET SESSION RESULT', result)
    } catch (e) {
      console.log('GET SESSION ERROR', e)
      result = {data: {merchant: '', token: '', expiry: new Date()}}
    }
    //RETURN WITH SESSION OBJECT CONTAINING MERCHANT ID & SESSION TOKEN
    return {
      ...result.data,
    }
  } catch (e) {
    throw e
  }
}

export default gpServerEmulator
