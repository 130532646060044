import {useState} from 'react'

export interface ICardWidgetCard {
  card_number?: string
  cvc?: string
  expiry_date?: string
  cardholder?: string
  amount?: number
  card_token?: string
  cvv_token?: string
  _allowAmount?: boolean
}

export interface IUseCardWidgetController {
  ready?: boolean
  height?: number
  mode?: string
  merchant?: string
  title?: string
  card?: ICardWidgetCard
  style?: any
  visible?: boolean
  buttonLabel: string | null
}

export interface ICardWidgetControllerResult {
  error: boolean
  status: number
  message: string
  card_token: string
  cvc_token: string
  cardholder: string
  expiry_month: string
  expiry_year: string
  amount: number
}

export const useCardWidgetController = (args?: IUseCardWidgetController) => {
  let options = {
    ready: false,
    height: 600,
    mode: '',
    //merchant: '',
    merchant: 'EWAYTEST1',
    buttonLabel: null,
    visible: true,
    expiry: 0,
    ...args,
  }

  const [state, setState] = useState(options)
  //const [card, setCard] = useState<ICardWidgetCard>()

  //General / <ulti Setter
  const set = (obj: any) => {
    setState(prevState => {
      if (obj && obj.card) {
        let baseObj = {...obj}
        delete baseObj.card
        return {
          ...prevState,
          ...baseObj,
          card: {...prevState.card, ...obj.card},
        }
      } else {
        return {...prevState, ...obj}
      }
    })
  }

  //Reset works same as set , but resets to default Values (except merchant)
  const reset = (obj?: any) => {
    setState(prevState => {
      return {
        ...prevState,
        ...options,
        merchant: prevState.merchant,
        ...obj,
      }
    })
  }

  const setReady = (flag: boolean) => {
    setState(prevState => {
      return {...prevState, ready: flag}
    })
  }
  const setHeight = (height: number) => {
    setState(prevState => {
      return {...prevState, height: height}
    })
  }
  const setResult = (obj: ICardWidgetControllerResult) => {
    setState(prevState => {
      return {...prevState, result: obj}
    })
  }

  const setCard = (obj: ICardWidgetCard) => {
    setState(prevState => {
      return {...prevState, card: {...prevState.card, ...obj}}
    })
  }

  const clearCard = (allow = false) => {
    setState(prevState => {
      return {...prevState, card: {_allowAmount: allow, amount: 0}}
    })
  }

  const setMerchant = (id: string) => {
    setState(prevState => {
      return {...prevState, merchant: id}
    })
  }

  const setMode = (id: string) => {
    setState(prevState => {
      return {...prevState, mode: id}
    })
  }

  const setExpiry = (secs: number) => {
    setState(prevState => {
      return {...prevState, expiry: secs}
    })
  }

  const setVisible = (flag: boolean) => {
    setState(prevState => {
      return {...prevState, visible: flag}
    })
  }

  const postMessage = (obj: any) => {
    let ele: any = document.getElementById('card-frame')
    if (ele && ele.contentWindow && ele.contentWindow.postMessage) ele.contentWindow.postMessage(obj, '*')
  }

  return {
    ...state,
    state: state,
    postMessage,
    reset,
    set,
    setCard,
    setHeight,
    setMerchant,
    setMode,
    setReady,
    setResult,
    setExpiry,
    setVisible,
    clearCard,
  }
}
