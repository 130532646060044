import {faker} from '@faker-js/faker'
import _random from 'lodash/random'
import {ulid} from 'ulid'

export interface IRandomCard {
  min?: number
  max?: number
}

export const randomCard = (merchant: string) => {
  let cardholder, expiry_month, expiry_year, expiry_date, pick, amount
  let liveList: any = []
  let demoList: any = []
  let merchPrefix = String(merchant).trim().substring(0, 4)
  let maxAmount = 2500
  switch (merchPrefix) {
    case 'TEST':
      console.log('ADYEN LIST')
      liveList = virtualCards
      demoList = adyenTestCards
      break
    case 'PAYR':
      console.log('PAYRIX LIST')
      liveList = virtualCards
      demoList = payrixTestCards
      maxAmount = 20
      break
    default:
      console.log('EWAY LIST')
      liveList = virtualCards
      demoList = ewayTestCards
  }

  if (process.env.REACT_APP_PAYGATE_MODE === 'production') {
    pick = Math.floor(Math.random() * virtualCards.length)
    amount = _random(20, 125) / 100
    cardholder = `GP TEST ${pick}`
    return {...liveList[pick], cardholder, amount}
  }
  pick = Math.floor(Math.random() * demoList.length)
  amount = Math.floor(Math.random() * maxAmount)
  cardholder = faker.name.findName()
  expiry_month = String(demoList[pick].expiry_date).substring(0, 2)
  expiry_year = String(demoList[pick].expiry_date).slice(-2)
  expiry_date = expiry_month + '/' + expiry_year
  let reference = ulid()
  return {
    ...demoList[pick],
    amount: amount,
    expiry_date,
    reference: reference.substring(reference.length - 10, reference.length),
  }
}

export const virtualCards = [
  {
    type: 'VISA',
    card_number: '4813 9000',
    cardholder: 'GP Test 1',
    expiry_date: '09/25',
    cvc: '584',
    country: 'AU',
  },
  {
    type: 'VISA',
    card_number: '4813 9000',
    cardholder: 'GP Test 2',
    expiry_date: '09/25',
    cvc: '974',
    country: 'AU',
  },
  {
    type: 'VISA',
    card_number: '4813 9000',
    cardholder: 'GP Test 3',
    expiry_date: '09/25',
    cvc: '113',
    country: 'AU',
  },
]

export const ewayTestCards = [
  {
    type: 'VISA',
    card_number: '4444 3333 2222 1111',
    cardholder: 'Eway Nancy Visa',
    expiry_date: '03/30',
    cvc: '737',
    country: 'AUD',
  },
  {
    type: 'VISA',
    card_number: '4444 3333 2222 1111',
    cardholder: 'Eway Bill Visa',
    expiry_date: '03/28',
    cvc: '888',
    country: 'AUD',
  },
  {
    type: 'MCARD',
    card_number: '5105 1051 0510 5100 ',
    cardholder: 'Eway Harry Masters',
    expiry_date: '05/28',
    cvc: '777',
    country: 'AUD',
  },
  {
    type: 'MCARD',
    card_number: '2223 0000 4840 0011',
    cardholder: 'Eway June Masters',
    expiry_date: '08/27',
    cvc: '555',
    country: 'AUD',
  },
  {
    type: 'AMEX',
    card_number: '3782 8224 6310 005',
    cardholder: 'Eway Bob Americano',
    expiry_date: '02/26',
    cvc: '7292',
    country: 'US',
  },
]

export const payrixTestCards = [
  {
    type: 'VISA',
    card_number: '4111 1111 1111 1111',
    cardholder: 'Payrix Visa 1',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'AU',
  },
  {
    type: 'VISA',
    card_number: '4012 8888 8888 1881',
    cardholder: 'Payrix Visa 2',
    expiry_date: '03/2030',
    cvc: '137',
    country: 'AU',
  },
  {
    type: 'VISA',
    card_number: '4242 4242 4242 4242',
    cardholder: 'Payrix Visa 3',
    expiry_date: '03/2030',
    cvc: '822',
    country: 'AU',
  },
  {
    type: 'MCARD',
    card_number: '5353 5353 5353 5351',
    cardholder: 'Payrix Mcard 1',
    expiry_date: '03/2030',
    cvc: '232',
    country: 'AU',
  },
  {
    type: 'MCARD',
    card_number: '5555 5555 5555 4444',
    cardholder: 'Payrix Mcard 2',
    expiry_date: '03/2030',
    cvc: '543',
    country: 'AU',
  },
  {
    type: 'MCARD',
    card_number: '5105 1051 0510 5100',
    cardholder: 'Payrix Mcard 2',
    expiry_date: '03/2030',
    cvc: '832',
    country: 'AU',
  },
  {
    type: 'AMEX',
    card_number: '3782 8224 6310 005',
    cardholder: 'Payrix Amex 1',
    expiry_date: '03/2030',
    cvc: '8232',
    country: 'AU',
  },
]

export const adyenTestCards = [
  {
    type: 'MCARD',
    card_number: '2222 4000 7000 0005',
    cardholder: 'Commercial Debit	CA	',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'CA',
  },
  {
    type: 'MCARD',
    card_number: '5555 3412 4444 1115',
    cardholder: 'Consumer	NL',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'NL',
  },
  {
    type: 'MCARD',
    card_number: '5577 0000 5577 0004',
    cardholder: 'Consumer	PL',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'PL',
  },
  {
    type: 'MCARD',
    card_number: '5555 4444 3333 1111',
    cardholder: 'Consumer	GB',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'GB',
  },
  {
    type: 'MCARD',
    card_number: '2222 4107 4036 0010',
    cardholder: 'Corporate	NL',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'NL',
  },
  {
    type: 'MCARD',
    card_number: '5555 5555 5555 4444',
    cardholder: 'Corporate	GB',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'GB',
  },
  {
    type: 'MCARD',
    card_number: '2222 4107 0000 0002',
    cardholder: 'Corporate Credit	NL',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'NL',
  },
  {
    type: 'MCARD',
    card_number: '2222 4000 1000 0008',
    cardholder: 'Credit	CA',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'CA',
  },
  {
    type: 'MCARD',
    card_number: '2223 0000 4841 0010',
    cardholder: 'Credit	NL',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'CA',
  },
  {
    type: 'MCARD',
    card_number: '2222 4000 6000 0007',
    cardholder: 'Debit	CA',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'CA',
  },
  {
    type: 'MCARD',
    card_number: '2223 5204 4356 0010',
    cardholder: 'Debit	NL',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'NL',
  },
  {
    type: 'MCARD',
    card_number: '2222 4000 3000 0004',
    cardholder: 'Fleet Credit	CA',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'CA',
  },
  {
    type: 'MCARD',
    card_number: '5100 0600 0000 0002',
    cardholder: 'Premium Credit	US',
    expiry_date: '12/2029',
    cvc: '737',
    country: 'US',
  },
  {
    type: 'MCARD',
    card_number: '2222 4000 5000 0009',
    cardholder: 'Purchasing Credit	CA',
    expiry_date: '03/2030',
    cvc: '737',
    country: 'CA',
  },
  {
    type: 'VISA',
    card_number: '4111 1111 4555 1142',
    cardholder: 'SECURITY CODE OPTIONAL	Classic	NL',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4111 1120 1426 7661',
    cardholder: 'EIGHT-DIGIT BIN	Debit	FR',
    expiry_date: '12/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4988 4388 4388 4305',
    cardholder: 'Classic	ES',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4166 6766 6766 6746',
    cardholder: 'Classic	NL',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4646 4646 4646 4644',
    cardholder: 'Classic	PL',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4000 6200 0000 0007',
    cardholder: 'Commercial Credit	US',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4000 0600 0000 0006',
    cardholder: 'Commercial Debit	US',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4293 1891 0000 0008',
    cardholder: 'Commercial Premium Credit	AU',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4988 0800 0000 0000',
    cardholder: 'Commercial Premium Debit	IN',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4111 1111 1111 1111',
    cardholder: 'Consumer	NL',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4444 3333 2222 1111',
    cardholder: 'Corporate	GB',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4001 5900 0000 0001',
    cardholder: 'Corporate Credit	IL',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4000 1800 0000 0002',
    cardholder: 'Corporate Debit	IN',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4000 0200 0000 0000',
    cardholder: 'Credit	US',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4000 1600 0000 0004',
    cardholder: 'Debit	IN',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4002 6900 0000 0008',
    cardholder: 'Debit	AU',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4400 0000 0000 0008',
    cardholder: 'Debit	US',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4484 6000 0000 0004',
    cardholder: 'Fleet Credit	US',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4607 0000 0000 0009',
    cardholder: 'Fleet Debit	MX',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4977 9494 9494 9497',
    cardholder: 'Gold	FR',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4000 6400 0000 0005',
    cardholder: 'Premium Credit	AZ',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4003 5500 0000 0003',
    cardholder: 'Premium Credit	TW',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4000 7600 0000 0001',
    cardholder: 'Premium Debit	MU',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4017 3400 0000 0003',
    cardholder: 'Premium Debit	RU',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4005 5190 0000 0006',
    cardholder: 'Purchasing Credit	US',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4131 8400 0000 0003',
    cardholder: 'Purchasing Debit	GT',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4035 5010 0000 0008',
    cardholder: 'Visa	FR',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4151 5000 0000 0008',
    cardholder: 'Visa Credit	US',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4571 0000 0000 0001',
    cardholder: 'Visa Dankort	DK',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'VISA',
    card_number: '4199 3500 0000 0002',
    cardholder: 'Visa Proprietary	FR',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'AMEX',
    card_number: '3700 0000 0000 002',
    cardholder: 'NL',
    expiry_date: '03/2030',
    cvc: '7373',
  },
  {
    type: 'AMEX',
    card_number: '3700 0000 0100 018',
    cardholder: 'SECURITY CODE OPTIONAL	NL',
    expiry_date: '03/2030',
    cvc: '7373',
  },
  {
    type: 'DINER',
    card_number: '3600 6666 3333 44',
    cardholder: 'US',
    expiry_date: '03/2030',
    cvc: '737',
  },
  {
    type: 'DINER',
    card_number: '3607 0500 0010 20',
    cardholder: 'SECURITY CODE OPTIONAL	NL',
    expiry_date: '03/2030',
    cvc: '737',
  },
]
