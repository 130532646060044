import {Route, Routes, Navigate} from 'react-router-dom'
import {LandingPage, CardWidgetDemo, EmbeddedClient} from '.'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/widget" element={<CardWidgetDemo />} />
      <Route path="/embed" element={<EmbeddedClient />} />
    </Routes>
  )
}
