import React, {useState, useEffect, Fragment, useRef} from 'react'
import {Box, Text, Grid, GridItem, Button, Select, CheckboxGroup, Checkbox, Stack, Flex, Spacer} from '@chakra-ui/react'
import {CardWidgetController, useCardWidgetController} from 'Library'
import {randomCard} from './randomCard'
import axios from 'axios'
import _get from 'lodash/get'

const Result = (props: any) => {
  let {data} = props
  let ele = []
  let keys = Object.keys(data)
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    let val = typeof data[key] === 'boolean' ? (data[key] ? 'true' : 'false') : data[key]
    if (key && val)
      ele.push(
        <Fragment key={i}>
          <GridItem />
          <GridItem key={`key${i}`}>{key}</GridItem>
          <GridItem key={`data${i}`}>{val}</GridItem>
          <GridItem />
        </Fragment>
      )
  }
  return (
    <Grid w="100%" m={3} templateColumns="1fr 150px 350px 1fr" gap="8px" alignContent={'center'}>
      {ele}
    </Grid>
  )
}

const getStorage = (props: any) => {
  let setTranList = _get(props, 'setTranList', null)
  let existing = window.localStorage.getItem('pay-widget-demo-trans')
  let obj = existing ? JSON.parse(existing || '') : {}
  if (setTranList) setTranList(obj)
  let keys = Object.keys(obj)
  let ele = []
  for (let i = keys.length - 1; i >= 0; i--) {
    let key = keys[i]
    // {obj[key].merchant}...${key.slice(-5)} ${obj[key].mode} ${obj[key].amount}
    ele.push(
      <option key={i} value={key}>
        {obj[key].MerchantID} {obj[key].ref} ${obj[key].amount}
      </option>
    )
  }
  return ele
}

const merchants =
  process.env.REACT_APP_PAYGATE_MODE === 'production' ? (
    [
      <Fragment key="ms_frag1">
        <option key="ms_1" value="EWAYTEST1">
          Eway Test 1
        </option>
        <option key="ms_2" value="PAYRIXTEST1">
          Payrix Test Merchant 1
        </option>
      </Fragment>,
    ]
  ) : (
    <Fragment key="ems_frag1">
      <option key="ems_1" value="EWAYTEST1">
        Eway Test Merchant 1
      </option>
      <option key="ems_2" value="PAYRIXTEST1">
        Payrix Test Merchant 1
      </option>
      <option key="ems_3" value="GUESTPOINT">
        Guestpoint
      </option>
      <option key="ems_4" value="TEST1">
        Adyen Test Merchant 1
      </option>
      <option key="ems_5" value="CAPTUREONLY">
        Capture Only
      </option>
    </Fragment>
  )

const modes = (
  <Fragment key="ms_frag2">
    <option key="ms_1" value="PAYMENT">
      Payment
    </option>
    <option key="ms_2" value="REFUND">
      Refund
    </option>
    <option key="ms_3" value="PREAUTH">
      Pre-Authorisaton
    </option>
    <option key="ms_4" value="ADJUST">
      Adjust Card
    </option>
    <option key="ms_5" value="CLAIM">
      Make Claim Against Pre-Auth
    </option>
    <option key="ms_6" value="CAPTURE">
      Capture Card Only
    </option>
    <option key="ms_7" value="VIEW">
      Sneak Peak Card Data (5 seconds)
    </option>
    <option key="ms_8" value="SHOW">
      Show Card (Max 5 minutes)
    </option>
  </Fragment>
)

export const CardWidgetDemo = () => {
  const tranSelectRef = useRef<HTMLSelectElement | null>(null)
  const widget = useCardWidgetController()
  const [result, setResult] = useState(null)
  const [allowAmount, setAllowAmount] = useState(false)
  const [tranSelect, setTranSelect] = useState<any[]>([])
  const [tranList, setTranList] = useState([])
  const [currentTransaction, setCurrentTransaction] = useState<string>()
  const [winSize, setWinSize] = useState('100%')
  const [merchantSelect] = useState(merchants)
  const [modeSelect] = useState(modes)
  const [selectedCards, setSelectedCards] = useState<any[]>([])
  const [allowedCards, setAllowedCards] = useState<string>('')

  const [interactionSelect] = useState([
    <Fragment key="di_frag">
      <option key="di_1" value="ALLOWAMOUNT">
        Allow Amount Entry
      </option>
      <option key="di_2" value="DISABLEAMOUNT">
        Disable Amount Entry
      </option>

      <option key="di_3" value="SWIPEDEMO">
        Simulate Card Swipe to Clipboard
      </option>
      <option key="di_4" value="STYLEDEMO">
        Change Styling and Title
      </option>
      <option key="di_5" value="FOCUSDEMO">
        Set Focus to Card Holder
      </option>
      <option key="di_6" value="PREFILLDEMO">
        Pre-Fill Marty Mastercard $1000
      </option>
      <option key="di_7" value="SIZEXS">
        Size xs
      </option>
      <option key="di_8" value="SIZESM">
        Size sm
      </option>
      <option key="di_9" value="SIZEMD">
        Size md
      </option>
      <option key="di_10" value="SIZELG">
        Size lg
      </option>
      <option key="di_11" value="WINSMALL">
        Window Small
      </option>
      <option key="di_12" value="WINMED">
        Window Medium
      </option>
      <option key="di_13" value="WIN100">
        Windows 100%
      </option>
    </Fragment>,
  ])

  useEffect(() => {
    setTranSelect(getStorage({setTranList}))
  }, [])

  useEffect(() => {
    let str = selectedCards.join().replaceAll(',', '')
    setAllowedCards(str)
  }, [selectedCards])

  useEffect(() => {
    widget.postMessage({
      AllowCardType: allowedCards || ' ', //Note: Cannot post '' must be space
    })
    widget.postMessage({HasButton: 'true'})
  }, [allowedCards, widget])

  useEffect(() => {
    //WRITE RESULTS TO LOCAL STORAGE
    let id = _get(result, 'id', null)
    if (id) {
      let existing = window.localStorage.getItem('pay-widget-demo-trans')
      let resultObj = existing ? JSON.parse(existing) : {}
      resultObj[id] = result
      if (widget.mode === 'PAYMENT' || widget.mode === 'PREAUTH') {
        window.localStorage.setItem('pay-widget-demo-trans', JSON.stringify(resultObj))
      }
      setTranSelect(getStorage({setTranList}))
    }
  }, [result, widget.mode])

  useEffect(() => {
    setTimeout(
      () =>
        widget.postMessage({
          Resize: true,
        }),
      1000
    )
    // eslint-disable-next-line
  }, [winSize])

  const loadCardView = async (val: string) => {
    setCurrentTransaction(val)
    //Make Call to Payment Gateway to Get Card details
    let result: any = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_PAYGATE_SERVER}/api/session/manage/get`,
      headers: {
        'X-API-KEY': `${process.env.REACT_APP_PAYGATE_APIKEY}`,
      },
      data: {
        ref: val,
      },
    })

    console.log('Paygate Demo - Get Session Object', result.data)
    widget.set({
      mode: 'VIEW',
      merchant: result.data.merchant_id,
      card: {
        id: result.data.id,
        card_number: result.data.card_mask,
        cardholder: result.data.cardholder,
        expiry_date: `${result.data.expiry_month}/${result.data.expiry_year.slice(-2)}`,
        cvc: '***',
        amount: result.data.amount,
        card_token: result.data.card_token,
        cvc_token: result.data.cvc_token,
      },
    })
  }

  const processInteraction = (type: string) => {
    switch (type) {
      case 'ALLOWAMOUNT':
        setAllowAmount(true) //Controls default setting
        //Change for existing Widget Display
        widget.setCard({
          _allowAmount: true,
        })
        break
      case 'DISABLEAMOUNT':
        setAllowAmount(false)
        widget.setCard({
          _allowAmount: false,
        })
        break
      case 'SWIPEDEMO':
        let swipe = `%B4242424242424242^CURTIS/CHRISTOPHER.       ^24062011627019988624  998      ?
        ;4242424242424242=24062011627019988624?`
        navigator.clipboard.writeText(swipe)
        break
      case 'STYLEDEMO':
        setResult(null)
        widget.postMessage({
          CardStyle: {backgroundColor: 'aliceblue'},
          Title: 'This is a Custom Title',
        })
        break
      case 'FOCUSDEMO':
        widget.postMessage({Focus: 'cardholder'})
        break
      case 'PREFILLDEMO':
        setResult(null)
        widget.setCard({
          card_number: '5555 4444 3333 1111',
          cvc: '737',
          expiry_date: '03/30',
          cardholder: 'Mr Marty Mastercard',
          amount: 1000,
          _allowAmount: allowAmount,
        })
        break
      case 'SIZEXS':
        widget.postMessage({
          Size: 'xs',
        })
        break
      case 'SIZESM':
        widget.postMessage({
          Size: 'sm',
        })
        break
      case 'SIZEMD':
        widget.postMessage({
          Size: 'md',
        })
        break
      case 'SIZELG':
        widget.postMessage({
          Size: 'lg',
        })
        break
      case 'WINSMALL': {
        setWinSize('350px')
        break
      }
      case 'WINMED': {
        setWinSize('550px')
        break
      }
      case 'WIN100': {
        setWinSize('100%')
        break
      }
      default:
    }
  }

  return (
    <Box h="100vh" w="100vw" p={1} bg="white">
      <Box p={2} bg="gray.100" borderRadius="5px" h="100%">
        <Text fontSize={22} fontWeight="600">
          Paygate-React-Library Demo V1.0.4 (Nov 17 2022) [{process.env.REACT_APP_PAYGATE_MODE}]
        </Text>
        <Text my={3}>
          The purpose of react-paygate-library is to simplify use of the Payment Gateway for React client applications.
          In order to use this library environmental variables for REACT_APP_PAYGATE_SERVER, REACT_APP_PAYGATE_APIKEY,
          REACT_APP_WIDGET with valid values must be provided. This page simulates a page made available by a Company /
          Group Application (eg: Guestpoint or Owner Accounting) to use the payment Gateway Widget. Everything outside
          of the purple box is the native application. Everything inside the purple box is a self standing payment
          widget located in a secured Guestpoint CDN.
        </Text>

        <Grid templateColumns="350px 1fr 10px" gap="5px">
          <GridItem>
            <Grid mt={3} templateColumns="1fr" gap="8px">
              <GridItem>
                <Text fontWeight={600} fontSize="20px" color="green">
                  MERCHANT [{widget.merchant}] {widget.mode}
                </Text>
              </GridItem>
              <Button
                disabled={tranSelect.length <= 0}
                variant="outline"
                colorScheme="green"
                onClick={() => {
                  window.location.reload()
                }}
              >
                Reset
              </Button>
              <Button
                disabled={tranSelect.length <= 0}
                variant="outline"
                colorScheme="red"
                onClick={() => {
                  window.localStorage.removeItem('pay-widget-demo-trans')
                  setTranSelect(getStorage({setTranList}))
                }}
              >
                Clear Transaction History
              </Button>
              <Select
                id="demo_tran_select"
                ref={tranSelectRef}
                placeholder="Select Previous Transaction"
                onChange={e => {
                  setResult(null)
                  loadCardView(e.target.value)
                }}
              >
                {tranSelect}
              </Select>
              <Select
                placeholder="Select Merchant"
                onChange={e => {
                  widget.setMerchant(e.target.value)
                }}
              >
                {merchantSelect}
              </Select>
              <Select
                placeholder="Select Mode"
                onChange={e => {
                  widget.setMode(e.target.value)
                }}
              >
                {modeSelect}
              </Select>
              <Select
                placeholder="Sample Interaction"
                onChange={e => {
                  processInteraction(e.target.value)
                }}
              >
                {interactionSelect}
              </Select>
              <CheckboxGroup colorScheme="green" defaultValue={selectedCards} onChange={sel => setSelectedCards(sel)}>
                <Stack spacing={[1, 5]} direction={['column', 'row']}>
                  <Checkbox value="4">Visa</Checkbox>
                  <Checkbox value="1">M/Card</Checkbox>
                  <Checkbox value="3">Amex</Checkbox>
                  <Checkbox value="6">Diners</Checkbox>
                </Stack>
              </CheckboxGroup>
              <Checkbox
                //defaultChecked
                onChange={e => {
                  widget.postMessage({ShowReference: `${e.target.checked}`, Resize: true})
                  console.log('Checked Value', e.target.checked)
                }}
              >
                Show Reference
              </Checkbox>
              <Button
                variant="outline"
                colorScheme="green"
                onClick={() => {
                  setResult(null)
                  widget.clearCard(true)
                }}
              >
                New Card Entry
              </Button>
              <Button
                variant="outline"
                colorScheme="green"
                onClick={() => {
                  setResult(null)
                  //Clear Current Transaction Selections
                  setCurrentTransaction('')
                  if (tranSelectRef && tranSelectRef.current) {
                    tranSelectRef.current.value = ''
                  }
                  setTranSelect([...tranSelect])

                  let card = randomCard(widget.merchant)
                  widget.setCard({...card, _allowAmount: allowAmount})
                }}
              >
                Randomise Card
              </Button>
              {/* <Button
                variant="outline"
                colorScheme="green"
                onClick={() => {
                  let ele: any = document.getElementById('card-frame')
                  ele.contentWindow.location.reload()
                }}
              >
                Refresh IFrame
              </Button> */}
              <Button
                variant="outline"
                colorScheme="green"
                onClick={() => {
                  setResult(null)
                  widget.setCard({
                    ...widget.card,
                    amount: 250,
                    _allowAmount: true,
                  })
                }}
              >
                Set Amount as $250 & Allow Edit
              </Button>
              <Button
                variant="outline"
                colorScheme="purple"
                disabled={_get(tranList, `${currentTransaction}.mode`, null) !== 'PREAUTH'}
                onClick={() => {
                  setResult(null)
                  widget.set({
                    mode: 'ADJUST',
                    card: {
                      ...widget.card,
                      id: currentTransaction,
                      amount: 50,
                      _allowAmount: true,
                      reason: 'Test Reason',
                    },
                  })
                }}
              >
                Adjust Authorisation
              </Button>
              <Button
                variant="outline"
                colorScheme="purple"
                disabled={_get(tranList, `${currentTransaction}.mode`, null) !== 'PREAUTH'}
                onClick={() => {
                  setResult(null)
                  widget.set({
                    mode: 'CLAIM',
                    card: {
                      ...widget.card,
                      id: currentTransaction,
                      amount: 100,
                      reason: 'Extra Cleaning Cigarettes',
                    },
                  })
                }}
              >
                Make $100 Claim on Authorisation
              </Button>
              <Button
                variant="outline"
                colorScheme="orange"
                disabled={_get(tranList, `${currentTransaction}.mode`, null) !== 'PAYMENT'}
                onClick={() => {
                  setResult(null)
                  widget.set({
                    mode: 'REFUND',
                    card: {
                      ...widget.card,
                      id: currentTransaction,
                      _allowAmount: true,
                    },
                  })
                }}
              >
                Refund Past Payment
              </Button>
            </Grid>
          </GridItem>
          <GridItem>
            <Box my={3} mx={3} p={5} borderRadius="5px" bg="purple.800">
              <Text mb={1} color="white">
                Inside this purple container is an iframe embedded into the host page or application (if not visible it
                is not active). It will send (or receive) messages from the parent page using CORS compliant message
                passing methodologies. For instance the height is passed back to the parent so it can provide responsive
                behaviours applicable to the size required by the widget which adapts automatically according to screen
                size. Key data is passed between parent and iFrame including MerchantID, session Key and card tokens.
              </Text>
              {currentTransaction && (
                <Text color="lightgreen" mb={2}>
                  Transaction Selected is {currentTransaction}
                </Text>
              )}
              <Text color="white" mb={2}>
                Session will timeout in approximately {widget.expiry} seconds
              </Text>
              {!result && (
                <Flex>
                  <Spacer />
                  <CardWidgetController w={'500px'} widget={widget} bg="purple.800" setResult={setResult} />
                  <Spacer />
                </Flex>
              )}
            </Box>
            {result && (
              <Box my={3} mx={3} p={5} borderRadius="5px" bg="cyan.100">
                <Text textAlign={'center'} fontSize="22px" color="blue" fontWeight={600}>
                  For demonstration Only - These details will never be displayed in a Production environment
                </Text>
                <Text textAlign={'center'} fontWeight={600} fontSize="18px">
                  The Widget has sent back a message with the following data
                </Text>
                <Result data={result} />
              </Box>
            )}
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}
