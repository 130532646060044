/***************************************************************************************
 **** getSessionKey() - Function to obtain Paygate Session Key via Guestpoint Server ***
 ***************************************************************************************
 Note: This links to gpServerEmulator() which will be replaced by API call to GP Server in real environment
 */
import _get from 'lodash/get'
import gpServerEmulator from './gpServerEmulator'
import {ISession} from 'Library'

interface IgetSessionKeyProps {
  //Note: Interface will change for live environment (depending on needs for GP Server Request)
  user: string
}

const getSessionKey = async (obj: IgetSessionKeyProps): Promise<ISession> => {
  /*** API CALL TO GUESTPOINT-SERVER TO OBTAIN SESSION KEY IF PAYMENT VALID FOR THIS USER */
  let user = _get(obj, 'user', '')
  let session = await gpServerEmulator({user}) //In live this will be http request
  return session
}
export default getSessionKey
