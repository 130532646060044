import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Flex, Box, Text, Grid, Button} from '@chakra-ui/react'

export const LandingPage = () => {
  const navigate = useNavigate()
  return (
    <Box h="100vh" w="100vw" p={3} bg="gray.200">
      <Text align="center" fontSize="28px" fontWeight={700} color="blue">
        Payment Gateway React Library (Demo)
      </Text>
      <Grid mt={2} gap="14px" templateColumns={{base: '1fr', lg: '1fr 1fr'}}>
        <Flex direction="column" p={2} bg="white" h="400px" borderRadius="6px" justifyContent={'center'}>
          <Button
            w="300px"
            p={4}
            fontSize="28px"
            size="xl"
            alignSelf={'center'}
            variant="solid"
            colorScheme="primary"
            onClick={() => {
              navigate('/widget')
            }}
          >
            Card Entry Widget
          </Button>
        </Flex>
        <Flex direction="column" p={2} bg="white" h="400px" borderRadius="6px" justifyContent={'center'}>
          <Button
            w="300px"
            p={4}
            fontSize="28px"
            size="xl"
            alignSelf={'center'}
            variant="solid"
            colorScheme="success"
            onClick={() => {
              navigate('/embed')
            }}
          >
            Embedded Client
          </Button>
        </Flex>
      </Grid>
    </Box>
  )
}
