/*********************************************************************
 *** PaygateClient() - Wrapper for Payment Gateway Embedded Client ***
 *********************************************************************
 Essential props:-
 merchant - the Payment Gateway Merchant Code for current user
 Merchant Code as well as session key obtained from Payment Gateway Server via the Guestpoint Server
 */
import React, {useEffect, useState} from 'react'
import {Box} from '@chakra-ui/react'
import _get from 'lodash/get'

export interface ISession {
  token: string
  merchant: string
  expiry: Date
}

export interface IPaygateClient {
  queryString?: string
  session: ISession
  loadingProps?: any
  spinnerProps?: any
  [other: string]: any
  children?: React.ReactNode
}

const PaygateClient = (props: IPaygateClient) => {
  let {loadingProps, spinnerProps, session, queryString = '', children, ...other} = props
  const [ready, setReady] = useState(false)
  const [height, setHeight] = useState(0)

  //Reveive Message Handler
  const receiveMessage = (event: any) => {
    let data = _get(event, 'data', {})
    //If REceived results contain ready then set ready as true
    let ready = _get(data, 'ready', false)
    if (ready) setReady(true)
  }

  //Post Message Handler
  const postMessage = (event: any) => {
    let ele: any = document.getElementById('paygate-client-frame')
    try {
      if (ele && ele.contentWindow && ele.contentWindow.postMessage) {
        ele.contentWindow.postMessage({...event}, '*') //Live need to replace '*' with allowed origin
      }
    } catch (e) {
      throw e
    }
  }

  let ele: any = document.getElementById('paygate-client-frame')
  let ref = React.useRef<HTMLInputElement>(null)

  /**** BIND EVENT LISTENER FOR IFRAME MESSAGING ****/
  useEffect(() => {
    //Add Event Listner on Init
    console.info('PAYMENT-GATEWAY EMBEDDED CLIENT V1.0.2')
    window.addEventListener('message', receiveMessage)
    return () => {
      // Cleanup Event Listener on Exit
      window.removeEventListener('message', receiveMessage)
      setReady(false)
    }
    // Override! - Need to use disable as eventListener should only be set once and the receiveMessage function needs local state
    // eslint-disable-next-line
  }, [])

  /**** ADJUST FOR CHANGES IN CLIENT HEIGHT ****/
  useEffect(() => {
    //Send API KEY TO PayGate Client
    let dim = ref.current?.getBoundingClientRect()
    let hgt = _get(dim, 'height', 0)
    if (ready && hgt) {
      setHeight(hgt)
      postMessage({height: hgt})
    }
  }, [ele, ready, ref])

  useEffect(() => {
    if (ready && session) {
      let dim = ref.current?.getBoundingClientRect()
      let hgt = _get(dim, 'height', 0)
      console.log('PatGate-Embedded-Client Post Message', session)
      postMessage({session, height: hgt})
    }
  }, [session, ready])

  /**** SHOW IFRAME WHEN READY AND SESSION KEY PROVIDED - OTHERWISE SHOW LOADING FRAME */
  return (
    <>
      <Box bg="whitesmoke" h="100%" ref={ref} {...other}>
        <iframe
          id="paygate-client-frame"
          title="Payment Gateway Embedded Client"
          width="100%"
          height={height}
          style={{
            border: 'none',
            overflow: 'hidden',
          }}
          src={`${process.env.REACT_APP_PAYGATE_CLIENT}?api${queryString ? '=' + queryString : ''}`}
          //src={`https://paygate.guestpoint.dev?api`}
        >
          {children}
        </iframe>
      </Box>
    </>
  )
}

export default PaygateClient
