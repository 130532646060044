import React, {ReactNode, useEffect, useState} from 'react'
import moment from 'moment'
import axios from 'axios'
import {Box} from '@chakra-ui/react'
import _get from 'lodash/get'

interface ICardWidgetController {
  widget: any
  title?: string
  bg?: string
  w?: string | number
  children?: ReactNode
  setResult: any
}

const CardWidgetController: React.FC<ICardWidgetController> = props => {
  const {widget, title = 'card_widget', bg = 'transparent', w = '100%', setResult, children, ...rest} = props

  const [ready, setReady] = useState(false)
  const [sessionKeys, setSessionKeys] = useState({})
  //  const [sessionExpired, setSessionExpired] = useState(false)
  //const db = useDB()
  let reason = _get(widget, 'card.reason', null)

  const receiveMessage = (event: any) => {
    // widget.receiveMessage(event)
    let data = _get(event, 'data', {})

    let {ready, resize, dimensions, message, type, source} = data
    if (message && message !== 'RESIZE') console.log('Paygate Demo - Received back iFrame Message', data)
    // Set Ready when ready message received back from iframe
    if (ready) setReady(true)
    if (resize) widget.setHeight(dimensions.height)
    if (
      message !== 'READY' &&
      message !== 'RESIZE' &&
      type !== 'ACTION' &&
      source !== 'react-devtools-bridge' &&
      source !== 'react-devtools-content-script' &&
      type !== 'webpackOk'
    )
      if (message === 'PAYRESULT') {
        setResult(data)
        console.log('WE HAVE PAY RESULT', data)
      }
  }

  const getSessionKeys = async () => {
    let obj: any = {
      method: 'POST',
      url: `${process.env.REACT_APP_PAYGATE_SERVER}/api/session/create`,
      data: {
        merchant_id: widget.merchant,
      },
      headers: {
        'x-api-key': process.env.REACT_APP_PAYGATE_APIKEY,
      },
    }
    try {
      let result = await axios(obj)
      let expiry = _get(result, 'data.expiry', 0)

      widget.setExpiry(expiry)
      if (result && result.status === 200) {
        setSessionKeys(_get(result, 'data', {}))
      }
    } catch (e) {
      console.error('Paygate-Library : Create Session Error', e)
    }
  }

  //Adjust Widget Session Timeout every 10 seconds
  useEffect(() => {
    if (widget && widget.expiry) {
      setTimeout(() => {
        widget.setExpiry(widget.expiry - 10)
        //if (widget.expiry - 1 <= 0) setSessionExpired(true)
      }, 10000)
    }
    //Override es-lint - We do not want re-render for widget hook all values
    // eslint-disable-next-line
  }, [widget.expiry])

  //Get Session Keys on Change of Merchant
  useEffect(() => {
    console.info(`PAYMENT GATEWAY LIBRARY - WIDGET CONTROLLER V1.0.3 ${process.env.REACT_APP_WIDGET}`)
    window.addEventListener('message', receiveMessage)
    // cleanup this component
    return () => {
      window.removeEventListener('message', receiveMessage)
      setReady(false)
    }
    // Override! - Need to use disable as eventListener should only be set once and the receiveMessage function needs local state
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    /* This is here just so that background of iframe can be set to the color of purple box (otherwise rounded borders not shown) */
    if (ready !== widget.ready) widget.setReady(ready)
  }, [ready, widget])

  useEffect(() => {
    getSessionKeys()
    //override es-lint  (Do not re-envoke for getSessionKeys Function)
    // eslint-disable-next-line
  }, [widget.merchant])

  //POST CHANGES TO WIDGET VIA POSTMESSAGE (ONLY WHEN WIDGET IS READY)
  useEffect(() => {
    if (ready) {
      let checkIn = moment().add(parseInt(String(Math.random() * 100)), 'days')
      let checkOut = moment(checkIn).add(parseInt(String(Math.random() * 10) + 1), 'days')
      widget.postMessage({
        Style: {backgroundColor: bg},
        MerchantID: _get(widget, 'merchant', null),
        AllowAmount: _get(widget, 'card._allowAmount', false),
        SessionKeys: sessionKeys,
        CheckInDate: checkIn.format('YYYY-MM-DD'),
        CheckOutDate: checkOut.format('YYYY-MM-DD'),
        Mode: _get(widget, 'mode', null),
        Title: _get(widget, 'title', null),
        Card: {...widget.card},
        Label: _get(widget, 'buttonLabel', null),
      })
    }
    //Override ese-lint do not want to change on widget methods
    // eslint-disable-next-line
  }, [ready, sessionKeys, widget.mode, widget.title, widget.card, widget.buttonLabel, widget.merchant, reason, bg])

  // if (sessionExpired)
  //   return (
  //     <Box>
  //       <Text fontWeight={600} fontSize='22px' color='red'>
  //         This session has expired
  //       </Text>
  //     </Box>
  //   )
  return (
    <Box sx={{display: widget.visible ? '' : 'none'}}>
      <iframe
        id="card-frame"
        title={title}
        height={widget.height}
        width={w}
        style={{
          border: 'none',
          overflow: 'hidden',
        }}
        src={`${process.env.REACT_APP_WIDGET}?process.env.REACT_APP_VERSION`}
        {...rest}
      >
        {children}
      </iframe>
    </Box>
  )
}

export default CardWidgetController
