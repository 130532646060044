import React, {useState, Fragment} from 'react'
import {Grid, GridItem, Box, Select, Button} from '@chakra-ui/react'

import {PaygateClient} from 'Library'
import {getSessionKey} from '.'

interface IPayGateClientDemo {
  children?: React.ReactNode
}

const EmbeddedClient = (props: IPayGateClientDemo) => {
  const [session, setSession] = useState({token: '', merchant: '', expiry: new Date()})
  const [component, setComponent] = useState('summary')

  const [userSelect] = useState([
    <Fragment key="ms_frag">
      <option key="ms_1" value="ddtis">
        ddtis (GUESTPOINT)
      </option>
      <option key="ms_2" value="vikastaxmode2">
        vikastaxmode2 (EWAYTEST1)
      </option>
      <option key="ms_3" value="vikasvillage">
        vikasvillage (PAYRIXTEST1)
      </option>
    </Fragment>,
  ])

  /*** ACTIVATE SESSION FOR SELECTED MERCHANT ***/
  const activateSession = async (user: string) => {
    let sessionObj = await getSessionKey({user})
    console.log('Library - Embedded Client get Session Key', sessionObj)
    setSession(sessionObj)
  }

  return (
    <Grid h="100vh" bg="gray.200" templateColumns="220px 1fr">
      <GridItem bg="slategray">
        <Box p={2} fontWeight={700} fontSize={18} color="white">
          PayGate-Client Demo
        </Box>
        <Box p={2} fontSize="16" color="white">
          This is a Demo of the Payment Gateway Client running inside of an iFrame (Guestpoint will interface in the
          same way). The part at the right of this sidebar (within the purple frame) is an iFrame.
        </Box>
        <Select
          p={2}
          bg="white"
          placeholder="Select User"
          onChange={e => {
            activateSession(e.target.value)
          }}
        >
          {userSelect}
        </Select>
        <Grid p={2} gap="5px">
          <Button
            w="100%"
            variant="outline"
            bg="white"
            colorScheme="success"
            onClick={() => {
              setComponent('summary')
            }}
          >
            SUMMARY
          </Button>
          <Button
            w="100%"
            variant="outline"
            bg="white"
            colorScheme="success"
            onClick={() => {
              setComponent('transactions')
            }}
          >
            TRANSACTIONS
          </Button>
          <Button
            w="100%"
            variant="outline"
            bg="white"
            colorScheme="success"
            onClick={() => {
              setComponent('settlements')
            }}
          >
            SETTLEMENTS
          </Button>
        </Grid>
      </GridItem>
      <GridItem bg="purple" p={2}>
        <PaygateClient bg="whitesmoke" session={session} queryString={component} />
      </GridItem>
    </Grid>
  )
}

export default EmbeddedClient
