import { extendTheme } from '@chakra-ui/react'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import * as colorSchemes from './colorSchemes'
import { default as cardTheme } from './card'
import { main } from './main'

const theme = extendTheme({
  breakpoints,
  main,
  colors: {
    ...colors,
    ...colorSchemes,
  },
  components: {
    Card: {
      ...cardTheme,
    },
  },
})
export default theme
